import { Link, useLocation } from "react-router-dom"

const activeClass = (location: ReturnType<typeof useLocation>, path: string) => {
  return location.pathname === path ?
    "font-bold"
    : "font-normal"
}

type BKProps = {
  location: ReturnType<typeof useLocation>, to: string, name: string
}

const BKLink = ({ location, to, name }: BKProps) => {
  return <Link to={to} className={`p-5 ${activeClass(location, to)}`}>{name}</Link>
}

export const Header = () => {
  const location = useLocation();
  return <nav className="flex flex-row">
    <BKLink location={location} to="/" name="Home" />
    <BKLink location={location} to="/about" name="About" />
    <BKLink location={location} to="/projects" name="Projects" />
  </nav>
}
