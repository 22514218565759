import { Clicky } from "../components/clicky"

export const About = () => {
  return <div>
    <h2>Employment</h2>
    <p>Software engineer living in Perth, working at Canva</p>
    <h2>Links</h2>
    <ul className="list-disc ml-8">
      <li>Resume <Clicky to="/resume.pdf" /></li>
      <li>Github <Clicky to="https://github.com/bpkneale" /></li>
    </ul>
    <h2>Say hello</h2>
    <a href="mailto:hello@ben-k.dev">hello@ben-k.dev</a>
  </div>
}
