import { Linky } from "../components/linky"

export const MakeMovingEasy = () => {
  return <div>
    <h1>Make Moving Easy - Privacy Policy</h1>
    <h2>Preamble</h2>
    <p>I am committed to providing quality services to you and this policy outlines my ongoing obligations to you in respect of how I manage your Personal Information.
I have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The NPPs govern the way in which I collect, use, disclose, store, secure and dispose of your Personal Information.
A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner at <Linky to="https://www.oaic.gov.au" text="www.oaic.gov.au" />
</p>
    <h2>Personal Information</h2>
    <p>This app uses your camera to detect QR codes for the purpose of relating them to previously saved photos</p>
    <p>All Personal Information is stored locally on your phone and is not shared with anyone</p>
    <h2>Policy Updates</h2>
    <p>This Policy may change from time to time and is available on my website</p>
    <h2>Privacy Policy Complaints and Enquiries</h2>
    <p>If you have any queries or complaints about our Privacy Policy please contact me at <Linky to="mailto:hello@ben-k.dev" text="hello@ben-k.dev" /></p>
  </div>
}
