import { Switch, Route, useLocation } from "react-router-dom"
import { About } from "../pages/About";
import { Home } from "../pages/Home";
import { Projects } from "../pages/Projects";
import { Transition } from '@headlessui/react'
import { PageTitle } from "./pagetitle";
import { MakeMovingEasy } from "../privacy/MakeMovingEasy";

const TitleCase = (text: string) => {
  if(text.length > 1) {
    return `${text[1].toLocaleUpperCase()}${text.slice(2)}`
  }
  return "Home"
}

const TransitionWrap = ({ children }: { children: React.ReactNode }) => {
  return <Transition
      show={true}
      appear={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
    {children}
  </Transition>
}

export const Content = () => {
  const location = useLocation();
  return <div className="flex flex-col">
    <PageTitle text={TitleCase(location.pathname)} />
    <main className="h-full flex flex-col flex-grow align-center justify-center p-5">
      <Switch>
        <Route path="/about"> <TransitionWrap><About /></TransitionWrap> </Route>
        <Route path="/projects"><TransitionWrap><Projects /></TransitionWrap></Route>
        <Route path="/privacy/make-moving-easy"><TransitionWrap><MakeMovingEasy /></TransitionWrap></Route>
        <Route path="/"><TransitionWrap> <Home /> </TransitionWrap> </Route>
      </Switch>
    </main>
  </div>
}
