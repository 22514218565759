import { Clicky } from "../components/clicky"

export const Projects = () => {
  return <div>
    <h2>Things I'm working on</h2>
    <ul className="list-disc ml-8">
      <li>My Emergency WA App
        <ul style={{ listStyleType: "circle" }} className="ml-8">
          <li>iOS <Clicky to="https://apps.apple.com/au/app/my-ewa/id1611938271" /></li>
          <li>Android <Clicky to="https://play.google.com/store/apps/details?id=rodeo.mango.apps.ewa" /></li>
        </ul>
      </li>
      <li>Roo watcher
        <ul style={{ listStyleType: "circle" }} className="ml-8">
          <li>Website <Clicky to="https://roo.ben-k.dev" /></li>
          <li>Featured at core-electronics <Clicky to="https://core-electronics.com.au/projects/rooberry-pi" /></li>
        </ul>
      </li>
      <li>My home's solar output and data <Clicky to="https://solar.ben-k.dev" /></li>
      <li>Cosy cuppies website <Clicky to="https://github.com/bpkneale/cosycuppies" /></li>
    </ul>
  </div>
}
